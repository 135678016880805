<script lang="ts" setup>
const props = defineProps({
  locationReference: String,
});
</script>

<template>
  <v-container fluid class="app-location">
    <header class="app-welcome-message">
      <h1>
        Locatie: {{ props.locationReference }}
        <router-link v-if="$route.name != 'Location' && props.locationReference" :to="{ name: 'Location', params: { reference: props.locationReference } }">
          <v-icon>mdi-undo-variant</v-icon></router-link
        >
        <router-link v-else :to="{ name: 'Locations' }"> <v-icon>mdi-undo-variant</v-icon></router-link>
      </h1>
    </header>

    <router-view></router-view>
  </v-container>
</template>
