var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-location", attrs: { fluid: "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c(
          "h1",
          [
            _vm._v(" Locatie: " + _vm._s(_setup.props.locationReference) + " "),
            _vm.$route.name != "Location" && _setup.props.locationReference
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Location",
                        params: { reference: _setup.props.locationReference },
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-undo-variant")])],
                  1
                )
              : _c(
                  "router-link",
                  { attrs: { to: { name: "Locations" } } },
                  [_c("v-icon", [_vm._v("mdi-undo-variant")])],
                  1
                ),
          ],
          1
        ),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }